<template>
  <el-container style="height: 100%; overflow: auto">
    <el-header>
      <navtop></navtop>
    </el-header>
    <el-main>
      <div
        class="contentmain"
        style="margin-top: 20px"
        :style="{ 'min-height': sheight + 'px' }"
      >
        <el-row class="tac" style="margin-bottom: 80px">
          <el-col :span="5">
            <el-menu
              class="el-menu-vertical-demo"
              text-color="#111111"
              :default-active="tabindex"
              active-text-color="#00BCFF"
              @open="handleOpen"
              @close="handleClose"
              style="width: 240px"
            >
              <el-submenu
                v-for="(d, index) in list"
                :index="index + ''"
                :key="index"
              >
                <template slot="title">
                  <span>{{ d.name }}</span>
                </template>
                <el-menu-item-group
                  class="tebie"
                  v-for="(d1, i) in d.list"
                  :key="i"
                >
                  <el-menu-item
                    :index="index + '-' + i"
                    @click="toabout(d1.agreementType.itemValue, d1.name)"
                    >{{ d1.name }}</el-menu-item
                  >
                </el-menu-item-group>
              </el-submenu>
            </el-menu>
          </el-col>
          <el-col :span="19">
            <div class="center-content" style="padding-left: 10px">
              <router-view></router-view>
            </div>
          </el-col>
        </el-row>
      </div>
      <footerbottom></footerbottom>
    </el-main>
  </el-container>
</template>

<script>
import footerbottom from "../public/footerbottom.vue";
import navtop from "../public/navtop.vue";
export default {
  components: {
    navtop,
    footerbottom,
  },
  data() {
    return {
      tabindex: "0-1",
      sheight: "",
      list: [],
    };
  },
  created() {
    this.sheight = document.documentElement.scrollHeight - 80;
    this.$api.agreement("get").then((res) => {
      this.list = res.data;
      const { id, title } = this.$route.query;

      for (let a = 0; a < this.list.length; a++) {
        for (let b = 0; b < this.list[a].list.length; b++) {
          let d = this.list[a].list[b];
          if (d.name == title && d.agreementType.itemValue == id) {
            this.tabindex = a + "-" + b;
          }
        }
      }
    });
  },
  activated() {
    this.sheight = document.documentElement.scrollHeight - 80;
  },
  methods: {
    handleOpen(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    },
    gotonext(path, val) {
      // console.log('进入了跳转',path,this.$route.path)
      this.tabindex = val;
      this.$router.push(path);
      // this.$router.push('/deliveryrecord');
    },
    toabout(id, title) {
      window.location.href = window.location.origin + "/#/aboutus?id=" + id + "&title=" + title;
      window.location.reload();
      //   console.log(id);
      //   this.$router.replace({
      //     path: "/aboutus?id=" + id,
      //   });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-submenu,
/deep/.el-menu-item {
  padding: 0;
}
// /deep/.is-active{
// 	position: relative;
// }
// /deep/.is-active :after{
// 	position: absolute;
// 	content: '';
// 	width: 4px;
// 	height: 12px;
// 	bottom: 50%;
// 	background: #00BCFF;
// 	left: 2px;
// 	transform: translate(-50%, 60%);
// }
.tebie /deep/.el-menu-item {
  padding-left: 20px !important;
  color: #666666 !important;
}
.tebie /deep/.is-active {
  color: #00bcff !important;
}

.content {
  padding: 20px 0 200px 0;
}
.center-content {
  // height: 600px;
  // background-color: #0000FF;
}

.el-header {
  background-color: #ffffff;
  color: #333;
  text-align: center;
  height: 56px !important;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.1) !important;
}
.el-footer {
  height: 352px !important;
  padding: 0 0 !important;
  margin: 0 0 !important;
}
.el-aside {
  background-color: #d3dce6;
  color: #333;
  text-align: center;
  line-height: 200px;
}

.el-main {
  background-color: #f3f3f3;
  margin-top: 56px;
  padding: 0;
}
</style>
