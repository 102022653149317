<template>
  <div class="f-main">
    <div class="f-tab font14">
      <div class="one">
        <div class="o-left font14">
          <div class="ol-main" v-for="(d, index) in list" :key="index">
            <div class="font16">{{ d.name }}</div>
            <div
              @click="toabout(d1.agreementType.itemValue, d1.name)"
              v-for="(d1, i) in d.list"
              :key="i * 100"
            >
              {{ d1.name }}
            </div>
          </div>
<!--         <div class="ol-main">-->
<!--            <div class="font16">企业服务</div>-->
<!--            <div class="">入驻流程</div>-->
<!--            <div class="">常见问题</div>-->
<!--            <div class="">其他合作</div>-->
<!--          </div>-->
          <div class="ol-main">
<!--            <div class="font16">新手指南</div>-->
<!--            <div class="">新手指南</div>-->
            <div class="" @click="towenti()">常见问题</div>
            <div class="" @click="toResume">简历中心</div>
            <div class="" @click="toReport">投诉举报</div>
          </div>
        </div>
        <div class="o-right font14">
          <div class="or-main">
            <img src="../../assets/download.png" />
            <div>下载APP</div>
          </div>
          <div class="or-main">
            <img src="../../assets/wechat.jpeg" />
            <div>关注公众号</div>
          </div>
        </div>
      </div>
      <el-divider></el-divider>
      <div class="two">Freemen.work 版权所有 浙ICP备20000153号-2</div>
      <div class="three">
        <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #fff"
          >浙ICP备20000153号-2</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.$api.agreement("get").then((res) => {
      this.list = res.data;
    });
  },
  mounted() {
    // document.body.parentNode.style.overflowY = 'hidden';
  },
  methods: {
    towenti() {
      this.$router.push("/commonwt");
    },
    toResume(){
      this.$router.push("/information");
    },
    toReport(){
      this.$router.push("/help");
    },
    toabout(id, title) {
      window.location.href =
        window.location.origin + "/#/aboutus?id=" + id + "&title=" + title;
      window.location.reload();
      // console.log(id);
      // this.$router.replace({
      //   path: "/aboutus?id=" + id,
      // });
      // this.$router.push("/aboutus", {
      //   query: id,
      // });
    },
  },
};
</script>

<style lang="less" scoped>
.f-main {
  height: 352px;
  background: #05171d;
  position: relative;
  z-index: 20;
  .f-tab {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;
    .one {
      padding: 40px 0 20px 0;
      display: flex;
      justify-content: space-between;
      width: 900px;
      .o-left {
        display: flex;
        .ol-main {
          width: 120px;
          color: #b1b1b1;
          line-height: 2.3;
          div:hover {
            cursor: pointer;
          }
          a {
            color: #b1b1b1;
            text-decoration: none;
          }
          div:first-child {
            color: #ffffff;
            font-weight: 700;
          }
        }
      }
      .o-right {
        display: flex;
        .or-main {
          color: #ffffff;
          margin-left: 40px;
          text-align: center;
          img {
            width: 112px;
            height: 112px;
            background-color: #ffffff;
          }
          div {
            margin-top: 15px;
          }
        }
      }
    }
    .el-divider--horizontal {
      margin: 18px 0;
      background-color: #1e2f34;
    }
    .two {
      color: #b2b2b2;
    }
    .three {
      padding: 18px 0 0 0;
      color: #b2b2b2;
    }
  }
}
</style>
